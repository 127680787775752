@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$glass: hsla(0, 0%, 100%, 0.4);
$primary: hsla(16, 76%, 53%, 1);
$secondary: hsla(0, 0%, 0%, 0.8);
$text: hsla(0, 0%, 100%, 1);
$shadow: 0 10px 20px rgba(141, 138, 138, 0.5);
$backdrop: blur(10px) saturate(50%);
$border: 2px solid transparent;
$fontFamily: Roboto, sans-serif;


.App {
	text-align: center;
	box-sizing: border-box;

	button {
		font-weight: bold;
	}

	.servicesContent {

		width: 80vw;
		height: 63vh;
		.swiper{
			overflow: visible;
		}

		.swiper-button-prev {
			top: 50%;
			left: -1.5rem;
			height:3rem;
			width:3rem;
			font-size:3rem

		}

		.swiper-button-next {
			top: 50%;
			right: -1.5rem;
			height:3rem;
			width:3rem
		}
		.swiper-button-prev::after,.swiper-button-next::after{
			font-size: 1.75rem;
		}
		@media (max-width: 800px) {
			.swiper-button-next {
			font-size: 1rem;
			}
			.swiper-button-next::after,.swiper-button-prev::after{
				font-size: 1rem;

			}
			.swiper-button-next{

				height:2rem;
				width:2rem;
				right:-0
			}
			.swiper-button-prev{

				height:2rem;
				width:2rem;
				left:-0
			}
		}

		.swiper-slide {
			text-align: center;
			font-size: 1.5rem;
			box-shadow: none;
		}

		.swiper-wrap {
			position: relative;
		}

		.swiper-next-service,
		.swiper-prev-service {
			position: absolute;
			top: 0
		}



	}


	.glass {
		border: 1px solid $primary;
		border-radius: 1rem;
		background-color: $glass;
		backdrop-filter: $backdrop;
		box-shadow: $shadow;
		box-sizing: border-box;
	}


	.header {
		display: flex;
		z-index: 50;
		color: $secondary;
		background-color: $glass;
		margin: 0 2vw 0 2vw;
		box-shadow: $shadow;
		backdrop-filter: $backdrop;

		@media (max-width: 800px) {
			flex-direction: row-reverse;
			justify-content: left;
			position: relative;
		}
	}

	.logo {
		width: 25vw;

		span {
			font-size: 1.5rem;
		}

		img {
			width: 2rem;
			padding-right: .5rem;
		}

		@media (max-width: 800px) {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.sticky {
		position: fixed;
		top: 0;
		width: 95%;
	}

	.menu {
		display: flex;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		padding: 2vmin;
		max-width: 75vw;

		@media (max-width: 650px) {
			flex-direction: column;
			align-items: center;
		}

		.item {
			padding: 15px 3vw;
			font-family: $fontFamily;
			cursor: pointer;
			transition: color .3s ease-out;
			color: $secondary;
			font-size: 1.5vw;
			margin: 0.5vmin 0;
		}

		.active {
			color: $text;
		}

		.indicator {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			border-radius: 30px;
		}
	}

	.teamHolder {
		width: 100vw;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 800px) {
			height: 60vh;
		}

		.textContainer {
			width: 100%;
		}

		.swiperCarousel {
			width: 100%;
			margin-top: 15px;
		}

		.profileContainer {
			width: 300px;
			color: $secondary;
			transform: rotateY(8deg);
			border: 1px solid $primary;

			.role {
				font-size: 1.5rem;
				margin: 10px;
				transition: 0.5s;
				font-family: $fontFamily;
			}
		}

		.profileContainer:hover {
			transform: perspective(400px) rotateY(-8deg);
		}
	}

	.menuSize {
		width: 200px;
	}


	.ImageText {
		top: 66%;
		font-weight: 500;
		width: 80%;
		color: $secondary;
		font-size: 1.25rem;
	}
	.homeContent {
		text-align: center;
		font-size: 3rem;
		line-height: 8rem;
	}
	.clientParent {
		display: flex;
		justify-content: space-around;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 5vmin;

		width: 90vw;
		height: 50vh;
		@media (max-width: 650px) {
			height: 75vh;

		}
	}

	.clientWrapper {
		background-color: transparent;
		position: relative;
		perspective: 1000px;
		z-index: 40;
		width: 30%;
		height: 30%;
		@media (max-width: 1000px) {
			width: 45%;
			height: 30%;
		}
		@media (max-width: 650px) {
			width: 85%;
			height: 12%
		}
	}

	.client {
		position: relative;
		font-size: 1.2rem;
		font-weight: 600;
		color: $secondary;
		width: 100%;
		height: 100%;
		transition: transform 1s ease-out;
		transform-style: preserve-3d;
		cursor: pointer;
	}

	.clientFront,
	.clientBack {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		border: 1px solid $border;
		background-color: $glass;
		backdrop-filter: $backdrop;
		box-shadow: $shadow;
		backface-visibility: hidden;



		&>img {
			height: 10vmin,
		}

		&>a {
			color: $text;
		}
	}

	.clientBack {
		background-color: $primary;
		transform: rotateX(180deg);
		color: $text;

		.clientDesc {
			padding: 5px;
		}

	}

	.clientWrapper:hover .client {
		transform: rotateX(180deg);
	}

	.swiper {
		height: 100%;
		padding-bottom: 50px;
	}

	.centerItems {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-slide {
		background-position: center;
		background-size: cover;
		width: 250px;
		height: 250px;
		box-shadow: $shadow;
		position: relative;

		.name {
			position: absolute;
			width: 101%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1rem;
			font-weight: bold;
			bottom: 0;
			transform: translateX(10px);
			text-shadow: $primary 1px 0 10px
		}

		.nameTagBg {
			position: absolute;
			bottom: 0;
			width: 100%
		}
	}

	.swiper-slide img {
		display: block;
		width: 100%;
	}

	.employeeImage {
		border-radius: 5px;
		border: 4px solid $glass;
		background-clip: padding-box
	}

	.swiper-button-next,
	.swiper-button-prev {
		position: absolute;
		left: 25%;
		top: auto;
		bottom: .65rem;
		z-index: 11;
		color: $secondary;
		background-color: $primary;
		border-radius: 50%;
		width: 2rem;
		height: 2rem;


		&::after {
			font-size: 1rem;
			color: #fff;
			font-weight: 600;
		}
	}

	.swiper-button-next {
		left: auto;
		right: 25%;
	}

	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: $secondary;
	}

	.swiper-pagination {
		position: relative
	}

	.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: -2rem;
	}

	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		background: $secondary;
	}

	.swiper-pagination {
		position: relative
	}


	@media only screen and (max-width: 800px) {
		.swiper {
			margin: 0 5px;
			padding-bottom: 60px;
		}

		.swiper-button-prev {
			position: absolute;
			top: 80%
		}

		.swiper-button-next {
			left: auto;
			top: 80%
		}

		.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
			visibility: hidden;
		}
	}
}

.canvas {
	z-index: 100;
}

.body {
	width: 100vw;
	height: 100vh;
}

.mainContainer {
	width: 55%;
	height: 40%;
	transform: rotateZ(-2deg);
	transition: .5s;
	overflow: hidden;

	@media (max-width: 650px) {
		width: 80%;
		height: 50%;
	}

	.subContainer {
		width: 100%;
		height: 100%;
	}
}


.centerPara {
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 2rem;
	height:63vh;
	overflow: hidden;
	@media (max-width: 650px) {
		padding: 2rem;
	}

	&>div {
		text-align: left;
		font-weight: 300;
		color: $primary;
		margin-left: 4%;
		font-family: $fontFamily;
		font-size: 1.2rem;

	}
	.bolding{
		font-weight: 600;
	}

}
.home {
	padding: 1em;
	text-align: left;
	color: $secondary;
	font-family: $fontFamily;
	font-size: 1.125rem;
	line-height: 2rem;
	letter-spacing: .03125rem;
	@media (max-width: 650px) {
		padding:0em;
	}



}
.homeHeading {
	text-align: center;
	font-size: 2.4rem;
	line-height: 4rem;
	margin-bottom: 8rem;
	color:#454444;
	@media (max-width: 650px) {
		line-height: 3.5rem;
	}

}

.motto {
	text-align: center;
	p, h4{
		margin:10px;
		padding: 0;
	}
}

.highlightText {
	font-weight: 500;
	color: $primary;
	margin-left: 4%;
	font-family: $fontFamily;
	font-size: 1.8rem;
	margin-bottom: 1rem;
	text-shadow: $shadow;
}

.service {
	.para {
		line-height: 2rem;
		@media (max-width: 650px) {
			line-height: 1.5rem;
		}
	}

	p {
		margin-left: 2%;
		text-align: left;
		color: $secondary;
		font-family: $fontFamily;
		font-size: 1.125rem;
		letter-spacing: .03125rem;
		@media (max-width: 650px) {

		}
	}
	.tab{
		padding-left: 2rem;
		@media (max-width: 650px) {
			padding-left:0

		}
	}
}

.highlight {
	color: $primary;


}

.logoHighlight {
	color: $primary;
	margin: 10px;
	font-weight: 700;
}

.teamContent {
	padding-bottom: 1vw;
	color: $secondary;
	margin: 0 20%;
	width: 70%;
	text-align: center;
	font-size: 1.2rem;
	margin: 19vmin auto 0 auto;
	width: 70%;
	font-family: $fontFamily;

	@media (max-width: 650px) {
		width: 90%;
		margin: 25vmin auto 0 auto;
	}
}

.shape {
	position: fixed;
	width: 10vmin;
	height: 10vmin;
	border-width: 3px;
	border-style: solid;
	border-color: $primary;
	background-color: $glass;
}
.circle {
	border-radius: 50%;
}
.square {
	border-radius: 15%;
}

.center {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.serviceContainer {
	width: 100%;
	height: 100%;
}

.dot {
	width: 100vw;
	height: 90vh;
}

.technology {
	border: 2px solid transparent;
	border-radius: 1rem;
	backdrop-filter: $backdrop;
	box-shadow: $shadow;
	padding: 10px;
	background-color: $glass;
	width: 13vmin;
	margin: 2vmin;
	height: 13vmin;

	.technologyImage {
		background-size: cover;
		background-position: center;
		width: 13vmin;
		height: 13vmin;
	}
}

.glassSlider {
	border: 2px solid $primary;
	border-radius: 1rem;
	background-color: $glass;
	backdrop-filter: $backdrop;
	width: 78vw;
	height: 60vh;
	box-shadow: $shadow;
}

.drawer {
	margin: 5vmin;
	display: flex;
	border: 1px solid $glass;
	border-radius: .25rem;
	background-color: $glass;
	backdrop-filter: $backdrop;


	svg {
		width: 1.5em;
		height: 1.5em;
	}
}

.drawerLogo {
	width: 9vmin;
	height: 9vmin;
	background-size: cover;
	background-position: center;
	margin: 1vmin;
}

.techParent {
	height: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.footer {
	background: $secondary;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	&>div {
		transform: translate(0, 2.5vmin);
		color: $text;
	}
}
